$('.js-home-slider').slick({
  autoplay: true,
  dots: false,
  arrows: true,
  prevArrow: '<span class="slick-prev"><i class="fas fa-chevron-left arrow-color"></i></span>',
  nextArrow: '<span class="slick-next"><i class="fas fa-chevron-right arrow-color"></i></span>',
  adaptiveHeight: true,
  autoplaySpeed: 4000,
});

if ($('.hp').length) {
  if ($(window).width() < 640) {
    $('.js-vehicle-select').insertAfter('.vehicle-select__bottom');
    $('.js-footer-social').insertAfter('.support-list');
  }

  var moveHTML = debounce(function () {
    if ($(window).width() < 640) {
      $('.js-vehicle-select').insertAfter('.vehicle-select__bottom');
      $('.js-footer-social').insertAfter('.support-list');
    }
    if ($(window).width() > 640) {
      $('.js-vehicle-select').insertAfter('.vehicle-select__header');
      $('.js-footer-social').insertAfter('.footer__about');
    }
  }, 500);

  window.addEventListener('resize', moveHTML);
}


// instagram
$(window).load(function () {
  var $showNumber = 5;
  if ($(window).width() <= 667) {
    $showNumber = 4;
  } else {
    $showNumber = 5;
  }
  $('.hp #instafeed, .hp #instagram, .instagram-feed #instagram').slick({
    autoplay: true,
    autoplaySpeed: 0,
    speed: 9000,
    dots: false,
    arrows: false,
    slidesToShow: $showNumber,
    slidesToScroll: 1,
    swipeToSlide: true,
    cssEase: 'linear',
    responsive: [{
        breakpoint: 600,
        settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
        }
    }]
  });
});
