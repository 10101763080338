$('#vchrCountry select').change(function changeCountry() {
  $.get('/ajax/getStates.cfm?country=' + $(this).val(), function getStates(
    data
  ) {
    $('#vchrState select')
      .html(data)
      .prop('disabled', false);
  });
});
$('#form-left form').submit(function checkRequired() {
  var fail = false;
  var message = '';
  var errorCount = 0;
  var name = '';
  var title = '';
  $('#form-left form [required]').each(function checkFields(index, element) {
    var $element = $(element);
    if ($element.val() === '') {
      $element.css('background', 'red');
      fail = true;
      errorCount++;
      name = $element.siblings('label').replace('*', '');
      message += name + ' is required. ';
    }
  });
  if (fail) {
    title = $(this).attr('data-title');
    $('#modal')
      .html(
        '<p>Form submission failed for the following reason(s):' +
          message +
          '</p>'
      )
      .dialog({
        minHeight: 150,
        width: 300,
        modal: true,
        title: title,
        closeText: 'X',
        buttons: {
          Okay: function closeModal() {
            $(this).dialog('close');
          }
        }
      });
    return false;
  }
  return true;
});


if($('#contact').length) {
  /////// Vehicle Information //////
  $('#vYear').change(function(){
    var makeField = $('#vMake');
    makeField.prop('disabled', false);
  });
  
  $('#vMake').change(function(){
    var selected = $(this).children("option:selected").val();
    var modelField = $('#vModel');
    
    // implemeent model selections
    $.get('/ajax/getContactVModels.cfm?model=' +selected, function(data) {
      modelField.html(data).prop('disabled', false);
    });
  });

  $('.ymm-container').hide();
  $('.ymm-button button').on('click',function(){
    if($('.ymm-container').is(':hidden')) {
      $('.ymm-container').fadeIn();
      $(this).find('.far').addClass('fa-minus-square').removeClass('fa-plus-square');
    } else {
      $('.ymm-container').fadeOut();
      $(this).find('.far').addClass('fa-plus-square').removeClass('fa-minus-square');
    }
  });

  // adjust vehicle select order
  if($(window).width() < 640) {
    $('.vModel-container').insertAfter('.vMake-container');
  }
}

if($('#contact').length) {
  // location email
  $('#location').change(function(){
    $('.error').fadeOut();
    let location = $(this).find(':selected');
    let locationEmail = location.data('email');
    let locationCityState = location.data('citystate');
    $('input[name="locationEmail"]').val(locationEmail);
    $('input[name="locationCityState"]').val(locationCityState);
  });
}