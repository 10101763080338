/*
$('.js-gallery-main-img').magnificPopup({
  delegate: 'a',
  type: 'image',
  gallery: {
    enabled: true,
    navigateByImgClick: true,
    preload: [0, 1]
  }
});

$('.js-gallery-main-img').slick({
  adaptiveHeight: false,
  arrows: false,
  asNavFor: '#gallery-thumbs',
  fade: true,
  lazyLoad: 'ondemand'
});
$('#gallery-thumbs').slick({
  arrows: false,
  asNavFor: '.js-gallery-main-img',
  focusOnSelect: true,
  infinite: false,
  slidesToShow: 5,
  responsive: [{
    breakpoint: 600,
    settings: {
      dots: true,
      slidesToScroll: 3,
      slidesToShow: 3
    }
  }]
});

$(function () {
  // Header Filters
  $('#gallery-make').change(function changeMake() {
    $('#gallery-model').val('');
    $('#gallery-brand').val('');
    $('#gallery-search form').submit();
  });

  $('#gallery-model').change(function changeModel() {
    $('#gallery-brand').val('');
    $('#gallery-search form').submit();
  });

  $('#gallery-brand').change(function changeBrand() {
    $('#gallery-search form').submit();
  });

})



if(jQuery("#gallery #instafeed").length > 0) {
	var feed = new Instafeed({
		clientID: "1a861835f5e3470389211ef4ccc22420",
		accessToken: "292735285.1677ed0.f9d3e38fdf3d4c26b73fe9740e1d51f5",
		get: "user",
		userId: 292735285,
		limit: 24,
		resolution: "standard_resolution",
      template: "<div class=\"column\"><a href=\"{{image}}\" class=\"instainfo\" target=\"_blank\" data-caption=\"{{caption}}\" data-username=\"{{model.user.username}}\" data-profilepic=\"{{model.user.profile_picture}}\" data-bio=\"{{model.user.bio}}\" data-link=\"{{link}}\" style=\"background-image:url('{{image}}');\"></a></div>",
        after: function() {
            $('.instainfo').click(function instaPopup (e){
                // prevent hyperlink
                e.preventDefault();
                // setup variables from instagram data
                var $this = $(this);
                var $getUsername = $this.data('username');
                var $getCaption = $this.data('caption');
                var $getProfilePic = $this.data('profilepic');
                var $getBio = $this.data('bio');
                var $getLink = $this.data('link');
                var $getInstagramLink = '//www.instagram.com/tiresouth/';
                var $getImage = $this.attr('href');

                // implement into popup
                $('#instagram-popup')
                    .find('.instagram-popup-profilepic')
                    .attr('src', $getProfilePic);
                $('#instagram-popup')
                    .find('.instagram-popup-page')
                    .attr('href',$getInstagramLink);
                $('#instagram-popup')
                    .find('.instagram-popup-username')
                    .html($getUsername)
                    .attr('href',$getInstagramLink);
                $('#instagram-popup')
                    .find('.instagram-popup-link')
                    .attr('href',$getLink);
                $('#instagram-popup')
                    .find('.instagram-popup-img')
                    .attr('src',$getImage);
                $('#instagram-popup')
                    .find('.instagram-popup-caption')
                    .html($getCaption);

                // run magnific popup with variables set
                $.magnificPopup.open({
                    //items:items,
                    items:{
                        src: '#instagram-popup',
                        type: 'inline'
                    },
                    gallery: {
                      enabled: true,
                      navigateByImgClick: true,
                      preload: [0, 1]
                    }
                });
            });
        }
	});
	feed.run();

}
*/

$('#gallery-main-image').magnificPopup({
  delegate: 'a',
  type: 'image',
  gallery: {
    enabled: true,
    navigateByImgClick: true,
    preload: [0, 1]
  }
});

$('#gallery-main-image').slick({
  adaptiveHeight: false,
  arrows: false,
  asNavFor: '#gallery-thumbs',
  fade: true,
  lazyLoad: 'ondemand'
});
$('#gallery-thumbs').slick({
  arrows: false,
  asNavFor: '#gallery-main-image',
  focusOnSelect: true,
  infinite: false,
  slidesToShow: 5,
  responsive: [{
    breakpoint: 600,
    settings: {
      dots: true,
      slidesToScroll: 3,
      slidesToShow: 3
    }
  }]
});

$(function () {
  // Header Filters
  $('#gallery-make').change(function changeMake() {
    $('#gallery-model').val('');
    $('#gallery-brand').val('');
    $('#gallery-search form').submit();
  });

  $('#gallery-model').change(function changeModel() {
    $('#gallery-brand').val('');
    $('#gallery-search form').submit();
  });

  $('#gallery-brand').change(function changeBrand() {
    $('#gallery-search form').submit();
  });

});
